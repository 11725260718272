<template>
  <b-dialog v-model="visible" width="500px" title="导出">
    <el-form :model="formData" label-width="100px" label-suffix="：">
      <el-form-item label="导出范围" prop="scope">
        <el-radio-group style="margin-left: auto;" v-model="formData.scope">
          <el-radio-button label="persion">仅我自己</el-radio-button>
          <el-radio-button label="dept">部门全体人员</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="时间范围" prop="date">
        <el-date-picker
          v-model="formData.date"
          :clearable="false"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <b-btn-confirm @click="onConfirm">确定</b-btn-confirm>
      </el-form-item>
    </el-form>
  </b-dialog>
</template>

<script>
import { exportReportExcel } from '@/api/report'
export default {
  data() {
    return {
      visible: false,
      formData: {
        scope: 'persion',
        date: '',
      },
    }
  },
  computed: {
    deptId() {
      return this.$store.getters['user/deptId']
    },
  },
  methods: {
    open(val) {
      this.formData.scope = val.scope
      this.formData.date = [val.start_date, val.end_date]

      this.visible = true
    },
    onConfirm() {
      const params = {
        start_date: this.formData.date[0],
        end_date: this.formData.date[1],
      }
      if (this.formData.scope === 'dept') {
        params.department_id = this.deptId
      }
      console.log(this.formData)

      exportReportExcel(params)
        .then(res => {
          this.visible = false
        })
        .catch(() => {
          this.$message.error('下载失败')
        })
    },
  },
}
</script>
<style scoped>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 360px;
}
</style>
